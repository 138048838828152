var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-page-header',{staticClass:"mt-4",attrs:{"content":"Preview"},on:{"back":function($event){return _vm.$router.go(-1)}}}),(_vm.isAdmin)?[_c('div',{staticClass:"text-right mt-2 mb-2"},[_c('router-link',{attrs:{"to":{
          name: 'SatEditQuestion',
          query: {
            questionId: _vm.question.id
          }
        }}},[_c('el-button',{attrs:{"type":"primary","size":"medium"}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Edit ")])],1)],1)]:_vm._e(),_c('el-card',{staticClass:"test-paper mb-4"},[(
        _vm.question &&
          _vm.question.type === 'default' &&
          _vm.question.sat_passage_id > 0 &&
          _vm.question.sat_passage.content
      )?_c('MultipleChoiceWithPassage',{attrs:{"mode":"explanation","passageIntro":_vm.question.sat_passage.introduction
          ? _vm.question.sat_passage.introduction.intro
          : null,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"showLine":_vm.question.sat_sat_subject.sat_subject.name === 'reading',"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):(
        _vm.question &&
          _vm.question.type === 'default' &&
          (_vm.question.sat_passage === null ||
            _vm.question.sat_passage.content === null)
      )?_c('MultipleChoice',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):(_vm.question && _vm.question.type === 'math')?_c('Math',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer !== null ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):_vm._e()],1),(_vm.isAdmin)?[_c('QuestionInfo',{attrs:{"question":_vm.question}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }