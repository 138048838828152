var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.question)?_c('el-form',{attrs:{"size":"small"}},[_c('el-form-item',{attrs:{"label":"Domain and Skills"}},[(
          _vm.question.sat_question_tags && _vm.question.sat_question_tags.length > 0
        )?_vm._l((_vm.questionTags(
            _vm.question.sat_question_tags
          )),function(tag_analysis,key,index){return _c('span',{key:index},_vm._l((tag_analysis),function(tag){return _c('el-tooltip',{key:tag.id,staticClass:"item pb-2 mr-2",attrs:{"effect":"dark","content":tag.name,"placement":"top"}},[_c('el-tag',{staticClass:"ivy-tag",attrs:{"type":"success","size":"small"}},[_c('b',[_vm._v(_vm._s(tag.name))])])],1)}),1)}):_c('b',[_vm._v(" - ")])],2),_c('el-form-item',{attrs:{"label":"Subjects"}},[(_vm.question.sat_subject_id)?_c('div',[_c('b',[_vm._v(" "+_vm._s(_vm.subjects[_vm.question.sat_subject_id].name))])]):_vm._e()]),_c('el-form-item',{attrs:{"label":"Source"}},[(_vm.question.source === 1)?_c('b',[_vm._v(" SATMocks ")]):(_vm.question.source === 2)?_c('b',[_vm._v(" Official ")]):_vm._e()]),_c('el-form-item',{attrs:{"label":"Difficulty"}},[_c('div',[_c('b',[_vm._v(" "+_vm._s(_vm.question.difficulty ? _vm.difficultyArr[_vm.question.difficulty] : "-")+" ")])])]),_c('el-form-item',{attrs:{"label":"Correct"}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.getPer(_vm.question.correct_count / _vm.question.answered_count))+"% ")])])]),_c('el-form-item',{attrs:{"label":"Count"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"text-success"},[_c('b',[_vm._v(" "+_vm._s(_vm.question.answered_count)+" ")])])]}}],null,false,1260081680)}),_c('el-form-item',{attrs:{"label":"#Tests"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.question.exam_questions)?_c('div',[_c('el-popover',{attrs:{"placement":"left","trigger":"hover","width":"450"}},[_c('div',_vm._l((_vm.question.exam_questions),function(tag){return _c('router-link',{key:tag.id,staticClass:"exam-tag",attrs:{"to":{
                  name: _vm.routerName(tag.exam.test_type),
                  query: { id: tag.exam.test_id }
                }}},[_c('el-tag',{staticClass:"ml-1 mr-1 mb-1",attrs:{"type":"success","size":"small","effect":"dark"}},[_vm._v(" "+_vm._s(("" + (_vm.getCompanyExamTitle(tag.exam.title))))+" ")])],1)}),1),_c('b',{staticClass:"text-success",staticStyle:{"text-decoration":"underline","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(_vm._s(_vm.question.exam_questions.length))])])],1):_vm._e()]}}],null,false,643113578)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }