<template>
  <div>
    <el-form size="small" v-if="question">
      <el-form-item label="Domain and Skills">
        <template
          v-if="
            question.sat_question_tags && question.sat_question_tags.length > 0
          "
        >
          <span
            v-for="(tag_analysis, key, index) in questionTags(
              question.sat_question_tags
            )"
            :key="index"
            class=""
          >
            <el-tooltip
              v-for="tag in tag_analysis"
              :key="tag.id"
              class="item pb-2 mr-2"
              effect="dark"
              :content="tag.name"
              placement="top"
            >
              <el-tag type="success" size="small" class="ivy-tag">
                <b>{{ tag.name }}</b>
              </el-tag>
            </el-tooltip>
          </span>
        </template>
        <b v-else>
          -
        </b>
      </el-form-item>
      <el-form-item label="Subjects">
        <div v-if="question.sat_subject_id">
          <b> {{ subjects[question.sat_subject_id].name }}</b>
        </div>
      </el-form-item>
      <el-form-item label="Source">
        <b v-if="question.source === 1">
          SATMocks
        </b>
        <b v-else-if="question.source === 2">
          Official
        </b>
      </el-form-item>
      <el-form-item label="Difficulty">
        <div>
          <b>
            {{ question.difficulty ? difficultyArr[question.difficulty] : "-" }}
          </b>
        </div>
      </el-form-item>
      <el-form-item label="Correct">
        <div>
          <b
            >{{ getPer(question.correct_count / question.answered_count) }}%
          </b>
        </div>
      </el-form-item>
      <el-form-item label="Count">
        <template slot-scope="scope">
          <div class="text-success">
            <b> {{ question.answered_count }} </b>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="#Tests">
        <template slot-scope="scope">
          <div v-if="question.exam_questions">
            <el-popover placement="left" trigger="hover" width="450">
              <div>
                <router-link
                  v-for="tag in question.exam_questions"
                  :key="tag.id"
                  :to="{
                    name: routerName(tag.exam.test_type),
                    query: { id: tag.exam.test_id }
                  }"
                  class="exam-tag"
                >
                  <el-tag
                    type="success"
                    size="small"
                    effect="dark"
                    class="ml-1 mr-1 mb-1"
                  >
                    {{ `${getCompanyExamTitle(tag.exam.title)}` }}
                  </el-tag>
                </router-link>
              </div>
              <b
                class="text-success"
                slot="reference"
                style="text-decoration:underline;cursor: pointer;"
                >{{ question.exam_questions.length }}</b
              >
            </el-popover>
          </div>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat";
import _ from "lodash";
import Common from "@/mixins/common.js";

export default {
  components: {},

  mixins: [Common],

  props: ["question"],
  data() {
    return {
      subjects: null,
      difficultyArr: {
        "1": "Beginner",
        "2": "Easy",
        "3": "Medium",
        "4": "Hard",
        "5": "Super Advanced"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.getSubjects(); //獲取Subjects
  },

  methods: {
    routerName(type) {
      if (type === "Modules\\Practice\\Entities\\ComposePractice") {
        return "SatComposePracticeDetailAll";
      }
      if (type === "Modules\\Practice\\Entities\\Practice") {
        return "SatPracticeDetailAll";
      } else {
        return "SatPracticeDetailAll";
      }
    },
    getPer(val) {
      let str = 0;
      if (val > 0) {
        str = Math.round(val * 100);
      }
      return str;
    },
    shortTag(tag) {
      let matches = tag.match(/\b(\w)/g); // ['J','S','O','N']
      return matches.join(""); // JSON
    },
    questionTags(tags) {
      let questionTags = null;
      if (tags) {
        questionTags = _.groupBy(tags, "type");
      }
      return questionTags;
    },
    async getSubjects() {
      const res = await SAT.getSubjects();
      let subjects = {};
      res.sat_subjects.forEach(subject => {
        subjects[`${subject.id}`] = {
          name: this.titleCase(subject.name)
        };
      });
      this.subjects = subjects;
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item--small .el-form-item__content,
::v-deep .el-form-item--small .el-form-item__label {
  line-height: 20px;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
</style>
